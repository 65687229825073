import { useMemo, useRef } from 'react'
import PropTypes from 'prop-types';

// Deps
import RSelect, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import omit from 'lodash/omit'
import kebabCase from 'lodash/kebabCase'

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon'

const defaultProps = {
	isSearchable: false,
}

const Select = (props) => {
	const elem = useRef(RSelect);

	const elemProps = useMemo(() => {

		let newElemProps = {
			...defaultProps,
			...omit(props, ['className', 'theme', 'outline', 'async', 'label', 'multiple', 'type', 'disabled', 'id', 'noOptionsMessage']),
			inputId: props.id + '_autocomplete',
			id: props.id,
			instanceId: props.id + '_instance',
		};

		newElemProps.className = props.className + ' ' + props.theme + (props.outline ? ' outline' : '');

		if(props.async) {
			elem.current = AsyncSelect;
			newElemProps.isSearchable = true;
		}

		newElemProps.classNamePrefix = props.theme;

		if(props.disabled) {
			newElemProps.isDisabled = props.disabled;
		}

		if(props.readOnly) {
			newElemProps.menuIsOpen = false;
		}

		if(props.multiple) {
			newElemProps.name = newElemProps.name + '[]';
			newElemProps.isMulti = true;
		}

		if (newElemProps.isSearchable && !props.async) {
			newElemProps.filterOption = (option, search) => {
				let searchText = kebabCase(search);
				let matches = false;

				const lookup = (option, level = 1) => {
					for(const key in option) {
						let val = option[key];
						if(typeof val === 'string') {
							if (kebabCase(val).search(searchText) !== -1) { matches = true; }
						}
						else if(typeof val === 'number') {
							if (kebabCase(val.toString()).search(searchText) !== -1) { matches = true; }
						}
						else if(level < 3 && typeof val === 'object' && !Array.isArray(val)) {
							lookup(val, level + 1);
						}
					}
				}

				lookup(option);

				return matches;
			}
		}

		newElemProps.noOptionsMessage = () => { return props.noOptionsMessage; }

		return newElemProps;
	}, [props]);

	if(elemProps) {
		if(!elem.current) { return null; }
		const Element = elem.current;
		return (
			<Element
				components={{ DropdownIndicator, IndicatorSeparator, LoadingIndicator, Input }}
				{...elemProps} />
		)
	}
	else {
		return false;
	}
}

Select.defaultProps = {
	theme: 'if_select',
	className: '',
	placeholder: '',
	outline: false,
	openMenuOnFocus: true,
	noOptionsMessage: 'Seçenek yok.',
}

Select.propTypes = {
	noOptionsMessage: PropTypes.string,
	async: PropTypes.bool,
	multiple: PropTypes.bool,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	theme: PropTypes.string,
	outline: PropTypes.bool,
	className: PropTypes.string,
	id: PropTypes.string,
}

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<Icon name="angle-down" />
		</components.DropdownIndicator>
	);
};

const IndicatorSeparator = () => {
	return false;
}

const LoadingIndicator = () => {
	return (
		<Icon className="if_select__loader" name="spinner" />
	)
}

const computeInputStyle = isHidden => ({
	label: 'input',
	background: 0,
	border: 0,
	fontSize: 'inherit',
	opacity: isHidden ? 0 : 1,
	outline: 0,
	padding: 0,
	color: 'inherit',
	width: '100%',
	display: 'block',
});

const Input = (props) => {
	const {
		innerRef, isDisabled, isHidden, className, cx,
		innerProps
	} = useMemo(() => {
		const {
			innerRef, isDisabled, isHidden, className, cx,
			...innerProps
		} = props;
	
		return {
			innerRef, isDisabled, isHidden,
			className, cx,
			innerProps: omit(innerProps, ['id', 'clearValue', 'getStyles', 'getValue', 'hasValue', 'isMulti', 'isRtl', 'options', 'selectOption', 'selectProps', 'setValue', 'theme']),
		};
	}, [props]);
	
  
	return (
		<div className="if_select__search_input_wrap">
			<input
				className={cx({ input: true }, className)}
				ref={innerRef}
				style={computeInputStyle(isHidden)}
				disabled={isDisabled}
				{...innerProps}
				autoComplete="new-password" />
		</div>
	);
};

Input.propTypes = {
	innerRef: PropTypes.any,
	cx: PropTypes.any,
	className: PropTypes.string,
	isHidden: PropTypes.bool,
	isDisabled: PropTypes.bool,
}

export default Select