import { useEffect, useMemo } from 'react';
import classNames from 'classnames';

import omit from 'lodash/omit';

// Partials
import Select from '/views/partials/select';

const SelectInput = ({
	onChangePlainValue, onChange,
	className, label, validationError,
	value, disabled, innerContent,
	wrapperId,
	...props
}) => {

	// const inputRef = useRef();

	const plainValue = useMemo(() => {
		return value?.value ? value?.value.toString() : '';
	}, [value])

	const isFull = useMemo(() => !!plainValue?.length, [plainValue])

	useEffect(() => {
		onChangePlainValue(plainValue);
	}, [plainValue])

	const handleChange = newVal => {
		onChange(newVal);
	}

	const { ...computedProps } = useMemo(() => {
		let computedProps = {
			...omit(props, ['innerContent', 'onChange', 'placeholder', 'value', 'popLabel', 'validation', 'touched', 'className', 'hideError', 'onFeedback', 'elemClass', 'initialized']),
			value: value,
			placeholder: label,
			disabled,
			onChange: handleChange,
			className: props.elemClass ? props.elemClass : undefined,
		}

		return computedProps;
	}, [label, value, handleChange, props, disabled])

	return (
		<div className={classNames(className, { 'input-full' : isFull })} id={wrapperId}>
			<div className={'inputwrap-outerwrap' + (label ? '' : ' no-label')}>
				{label &&
					<label className="input-label" htmlFor={props.id}>
						<div className="label-text">
							{label}
							{/* {((vm.props.validation !== false && vm.props.validation.required) && vm.props.showValidationAsterisk) && <span className="label-asterisk">*</span>} */}
						</div>
					</label>
				}
				<div className="input-innerwrap">
					{innerContent}
					<Select {...computedProps} />
				</div>
			</div>
			{!!(validationError && !props.hideError) &&
				<div className="input-error">
					{validationError}
				</div>
			}
		</div>
	)
}

SelectInput.defaultProps = {
	placeholder: 'Seçiniz...',
	openMenuOnFocus: true,
	noOptionsMessage: 'Seçenek yok.'
}

export default SelectInput;